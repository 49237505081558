import React from "react";
import Layout from "../components/layout";
import { getItemFromStore } from "../helpers/hash";
import Logo from "../components/logo-svg";
import { featureList } from "../components/Navbar";
import ContactForm from "../components/ContactForm";

export default function Landing() {
  return (
    <div className="min-h-screen bg-neutral-100 relative">
      {grid}
      <section className="flex max-w-6xl mx-auto px-4 py-20">
        <div className="w-full md:w-1/2">
          <div>
            <div className="block -mx-8 w-72 shrink-0">
              <Logo classes={"text-brand-500"} />
            </div>

            {featureList && (
          <div className="mx-auto flex flex-col  gap-3 py-3 md:gap-5">
            {featureList.map((item, i) => {
              return (
                <>
                 
                  <div className="flex  flex-row  items-center" key={i}>
                    {item.icon && (
                      <item.icon
                        className="black-600 mr-2 hidden shrink-0 text-black sm:block"
                        size={16}
                      />
                    )}

                    <p className="text-left  text-xs sm:text-sm font-medium leading-6 text-black  ">
                      {item.item}
                    </p>
                  </div>
                </>
              )
            })}
          </div>
        )}
          </div>
        </div>
        <div className="w-full md:w-1/2 pl-4">
          <div className="bg-green-50 border border-green-600 text-green-700 p-3 mb-1 font-medium rounded-lg">
Get $500 off first remodel
          </div>
          <div className="my-4 bg-white s rounded-xl p-10" style={{boxShadow: '0 4px 9px rgba(0,0,0,.04), 0 6px 30px hsla(210,2%,46%,.2)'}}>
            <ContactForm/>
          </div>
        </div>
      </section>
    </div>
  );
}

const grid = (
  <svg
    width="97"
    height="197"
    viewBox="0 0 97 197"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute top-[10%] left-[-20px] "
  >
    <g clip-path="url(#clip0_3282_64464)">
      <circle cx="12.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="8.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="23.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="38.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="53.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="68.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="83.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="98.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="113.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="128.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="143.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="158.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="173.5" r="1.5" fill="#DAE0E3" />
      <circle cx="12.5" cy="188.5" r="1.5" fill="#DAE0E3" />
      <circle cx="27.5" cy="188.5" r="1.5" fill="#DAE0E3" />
      <circle cx="42.5" cy="188.5" r="1.5" fill="#DAE0E3" />
      <circle cx="57.5" cy="188.5" r="1.5" fill="#DAE0E3" />
      <circle cx="72.5" cy="188.5" r="1.5" fill="#DAE0E3" />
      <circle cx="87.5" cy="188.5" r="1.5" fill="#DAE0E3" />
    </g>
    <defs>
      <clipPath id="clip0_3282_64464">
        <rect x="-100" width="197" height="197" rx="98.5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
